/* Reset HTML */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Inter" serif; */
  font-family: 'Manrope', sans-serif;
  font-style: normal;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Chrome, Edge, and Safari */
::-moz-scrollbar-button,
::-webkit-scrollbar-button {
  width: 0px;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  /* border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d62929; */
  border-radius: 14px;
  background-color: #828282;
}


/* testing */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}